import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";

function ReportsPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <section className="relative bg-contain bg-center bg-no-repeat min-h-screen flex items-center mb-16"
      style={{marginTop: "-144px"}}>
      <div className="container mx-auto px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
          {pageContent.headerText}
        </h3>
        <p className="text-center mt-4">{pageContent.subHeaderText}</p>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS}
        fluid={data.backgroundImage.childImageSharp.fluid} alt="Background"/>
    </section>
  </DefaultLayout>;
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/reports.json"}) {
      childPagesJson {
        title
        description
        headerText
        subHeaderText
      }
    }
    backgroundImage: file(relativePath: {eq: "background-reports-2.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(ReportsPage);
